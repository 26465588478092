
import { computed } from 'vue'
import clone from 'just-clone'

const parseArrayAdressen = (arr) => Object.values(arr).map(el => {
  if (typeof el === 'string') return { adres: el }
  return typeof el?.adres === 'string' ? el : null
})

export default (props) => {
  return computed(() => {
    let adressen = []

    if (props?.adressen?.length && Array.isArray(props.adressen)) {
      adressen = parseArrayAdressen(props.adressen)
    }

    if (!adressen?.length && props?.adres?.length && Array.isArray(props.adres)) {
      adressen = parseArrayAdressen(props.adres)
    }

    if (!adressen?.length && props?.adres) {
      if (typeof props.adres === 'string' && props.adres) {
        adressen.push({ adres: props.adres })
      }
      if (typeof props.adres?.adres === 'string' && props.adres?.adres) {
        adressen.push(props.adres)
      }
    }

    return clone(adressen.filter(el => !!el))
  })
}

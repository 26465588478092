import LUCHTHAVENS from '@/constants/LUCHTHAVENS'

export default (value) => {
  if (!value) return null

  return LUCHTHAVENS.find(el => {
    if (typeof value === 'string') {
      const lower = value.toLowerCase()
      return el.place_id.toLowerCase() === lower || el.adres.toLowerCase() === lower || el.name.toLowerCase() === lower
    }
    if (value.place_id) return el.place_id.toLowerCase() === value.place_id.toLowerCase()
    if (value.adres) return el.adres.toLowerCase() === value.adres.toLowerCase()
    return false
  })
}

<template>
  <span :class="{ 'inline-flex gap-1': adressen.length === 1 }" v-if="adressen?.length">
    <span class="inline">
      <a
        :href="link"
        @click="link && link[0] == '#' ? handleCopy : undefined"
        :disabled="store.getters.isKantoorPermission"
        rel="noopener noreferrer nofollow"
        target="_blank"
        class="group"
      >
        <slot>
          <i v-if="icon" :class="icon" class="w-5" />
          <span class="group-hover:underline" v-if="adres" :class="{
            'text-orange-500 font-bold bg-red-100 px-2 text-left': showGeocodeStatus && (!adres.lng || !adres.lat),
            'text-green-600 font-bold': showGeocodeStatus && (adres.lng && adres.lat),
          }">
            {{ useFindLuchthaven(adres) ? 'Luchthaven ' : '' }}{{ useFindLuchthaven(adres)?.name || adres?.adres || adres }}
            <span class="tracking-tighter" v-if="showGeocodeStatus">
              <i v-if="adres.lng" class="fas fa-check text-xs text-green-600 rotate-2"></i>
              <i v-else class="fas fa-times text-xs text-red-600"></i>
              <i v-if="adres.lat" class="fas fa-check text-xs text-green-600 rotate-2"></i>
              <i v-else class="fas fa-times text-xs text-red-600"></i>
            </span>
          </span>
        </slot>
      </a>
      <button @click="handleCopy" class="bg-dashboard-dark text-white rounded text-xs px-1 opacity-60 hover:opacity-100 ml-2" v-if="adressen.length === 1" :disabled="isCopying">
        <i class="fas text-xs" :class="{ 'fa-copy': !isCopying, 'fa-spinner': isCopying }" />
      </button>
    </span>
  </span>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'
import useFindLuchthaven from '@/hooks/useFindLuchthaven'
import useParsedAdressen from '@/hooks/useParsedAdressen'
import { useStore } from 'vuex'

const props = defineProps({
  labelIndex: Number,
  icon: String,
  showGeocodeStatus: Boolean,
  adres: {
    type: [Array, String, Object],
    required: true,
  },
})

const store = useStore()
const adressen = useParsedAdressen(props)

const link = computed(() => {
  if (store.getters.isKantoorPermission) {
    return '#adres'
  }
  let result = 'https://www.google.com/maps/dir/'
  adressen.value.forEach((adres) => {
    result += `/${encodeURIComponent(adres.adres)}`
  })
  return result
})

const adresTest = computed(() => props.adres?.adres || props.adres)

const isCopying = ref(false)
const handleCopy = async (e) => {
  e.preventDefault()
  const text = adresTest.value
  if (typeof text !== 'string') return

  isCopying.value = true

  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    try {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    } catch (error) {
      // Nothing
    }
  }

  setTimeout(() => {
    isCopying.value = false
  }, 500)
}
</script>

export default [
  {
    name: 'Zaventem',
    land: 'Belgie',
    link: '/luchthavenvervoer/luchthavenvervoer-zaventem',
    adres: 'Brussels Airport (BRU), Leopoldlaan, 1930 Zaventem, België',
    geometry: { lat: 50.900999, lng: 4.485574 },
    place_id: 'ChIJ7XVSxL7dw0cR5CV_Dj5OUSQ',
    seperated: {
      route: 'Leopoldlaan',
      postal_code: '1930',
      locality: 'Zaventem',
      country: 'België',
    },
  },
  {
    name: 'Luik',
    land: 'Belgie',
    link: '/luchthavenvervoer/luchthavenvervoer-luik',
    adres: 'Liège Airport (LGG), Rue de l’Aéroport, 4460 Grâce-Hollogne, België',
    geometry: { lat: 50.641159, lng: 5.447912 },
    place_id: 'ChIJCeXJQCb8wEcRYw2sVrK9Xj0',
    seperated: {
      route: 'Rue de l’Aéroport',
      postal_code: '4460',
      locality: 'Grâce-Hollogne',
      country: 'België',
    },
  },
  {
    name: 'Charleroi',
    land: 'Belgie',
    link: '/luchthavenvervoer/luchthavenvervoer-charleroi',
    adres: 'Brussels South Charleroi Airport (CRL), Rue des Frères Wright 8, 6041 Charleroi, België',
    geometry: { lat: 50.462537, lng: 4.458821 },
    place_id: 'ChIJSTiRGSApwkcRdG2Lsuqp7Qw',
    seperated: {
      route: 'Rue des Frères Wright',
      postal_code: '6041',
      locality: 'Charleroi',
      country: 'België',
    },
  },
  {
    name: 'Oostende',
    land: 'Belgie',
    link: '/luchthavenvervoer/luchthavenvervoer-oostende',
    adres: 'Nieuwpoortsesteenweg 889, 8400 Oostende, België',
    geometry: { lat: 51.2041938, lng: 2.8698128 },
    place_id: 'ChIJS0sqwWSv3EcR3VNKEu8Zql4',
    seperated: {
      route: 'Nieuwpoortsesteenweg',
      postal_code: '8400',
      locality: 'Oostende',
      country: 'België',
    },
  },
  {
    name: 'Antwerpen',
    land: 'Belgie',
    link: '/luchthavenvervoer/luchthavenvervoer-antwerpen',
    adres: 'Luchthavenlei, 2100 Antwerpen, België',
    geometry: { lat: 51.2041938, lng: 2.8698128 },
    place_id: 'ChIJoRVJ_Vf3w0cRmcqeCv33EYQ',
    seperated: {
      route: 'Luchthavenlei',
      postal_code: '2100',
      locality: 'Antwerpen',
      country: 'België',
    },
  },
  {
    name: 'Kortrijk-Wevelgem',
    land: 'Belgie',
    link: '/luchthavenvervoer/luchthavenvervoer-kortrijk',
    adres: 'Luchthavenstraat 1, 8560 Wevelgem, België',
    geometry: { lat: 51.2041938, lng: 2.8698128 },
    place_id: 'ChIJH4aptQ0ww0cRmnSp_AveEpI',
    seperated: {
      route: 'Luchthavenstraat',
      postal_code: '8560',
      locality: 'Wevelgem',
      country: 'België',
    },
  },
  {
    name: 'Schiphol',
    land: 'Nederland',
    link: '/luchthavenvervoer/luchthavenvervoer-schiphol',
    adres: 'Amsterdam Airport Schiphol (AMS), Evert van de Beekstraat 202, 1118 CP Schiphol, Nederland',
    geometry: { lat: 52.310539, lng: 4.768274 },
    place_id: 'ChIJLRb94DThxUcRiPHO8YMV1cc',
    seperated: {
      route: 'Evert van de Beekstraat',
      postal_code: '1118 CP',
      locality: 'Schiphol',
      country: 'Nederland',
    },
  },
  {
    name: 'Eindhoven',
    land: 'Nederland',
    link: '/luchthavenvervoer/luchthavenvervoer-eindhoven',
    adres: 'Luchthavenweg 25, 5657 EA Eindhoven, Nederland',
    geometry: { lat: 51.458369, lng: 5.392056 },
    place_id: 'ChIJD8ehowvbxkcROiMX95VI9Y4',
    seperated: {
      route: 'Luchthavenweg',
      postal_code: '5657 EA',
      locality: 'Eindhoven',
      country: 'Nederland',
    },
  },
  {
    name: 'Parijs-Orly',
    land: 'Frankrijk',
    link: '/luchthavenvervoer/luchthavenvervoer-parijs-orly',
    adres: 'Luchthaven Orly (ORY), 94390 Orly, Frankrijk',
    geometry: { lat: 48.726243, lng: 2.365247 },
    place_id: 'ChIJHTtq-rF15kcRIoTbQ9feeJ0',
    seperated: {
      route: 'Luchthaven Orly',
      postal_code: '94390',
      locality: 'Orly',
      country: 'Frankrijk',
    },
    tolkosten: 40,
  },
  {
    name: 'Parijs-Charles de Gaulle',
    land: 'Frankrijk',
    link: '/luchthavenvervoer/luchthavenvervoer-parijs-charles-de-gaulle',
    adres: 'Luchthaven Parijs-Charles de Gaulle (CDG), 95700 Roissy-en-France, Frankrijk',
    geometry: { lat: 49.009691, lng: 2.547925 },
    place_id: 'ChIJW89MjgM-5kcRLKZbL5jgKwQ',
    seperated: {
      route: 'Luchthaven Parijs-Charles de Gaulle',
      postal_code: '95700',
      locality: 'Roissy-en-France',
      country: 'Frankrijk',
    },
    tolkosten: 40,
  },
  {
    name: 'Dusseldorf',
    land: 'Duitsland',
    link: '/luchthavenvervoer/luchthavenvervoer-dusseldorf',
    adres: 'Flughafen Düsseldorf (DUS), Flughafenstraße 120, 40474 Düsseldorf, Duitsland',
    geometry: { lat: 51.287615, lng: 6.766791 },
    place_id: 'ChIJN35I6yrIuEcRT-7AUdwf9pQ',
    seperated: {
      route: 'Flughafenstraße',
      postal_code: '40474',
      locality: 'Düsseldorf',
      country: 'Duitsland',
    },
  },
  {
    name: 'Frankfurt',
    land: 'Duitsland',
    link: '/luchthavenvervoer/luchthavenvervoer-frankfurt',
    adres: 'Frankfurt Airport (FRA), 60547 Frankfurt am Main, Duitsland',
    geometry: { lat: 50.037933, lng: 8.562152 },
    place_id: 'ChIJeflCVHQLvUcRMfP4IU3YdIo',
    seperated: {
      route: 'Frankfurt Airport',
      postal_code: '60547',
      locality: 'Frankfurt am Main',
      country: 'Duitsland',
    },
  },
].map(el => {
  el.google = {
    adres: String(el.adres),
    is_luchthaven: true,
    place_id: el.place_id,
    ...Object.assign({}, el.seperated),
    tolkosten: el.tolkosten || 0,
  }
  return el
})
